@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?fsx2ee');
  src:  url('../fonts/icomoon.eot?fsx2ee#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?fsx2ee') format('truetype'),
    url('../fonts/icomoon.woff?fsx2ee') format('woff'),
    url('../fonts/icomoon.svg?fsx2ee#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon($type){
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if $type == 'instagram' {
    content: "\e900";
  }
  @else if $type == 'cart' {
    content: "\e901";
  }
  @else if $type == 'shipping' {
    content: "\e902";
  }
  @else if $type == 'guide' {
    content: "\e903";
  }
  @else if $type == 'member' {
    content: "\e904";
  }
  @else if $type == 'favorite' {
    content: "\e905";
  }
  @else if $type == 'search' {
    content: "\f002";
  }
  @else if $type == 'chevron-left' {
    content: "\f053";
  }
  @else if $type == 'chevron-right' {
    content: "\f054";
  }
  @else if $type == 'chevron-up' {
    content: "\f077";
  }
  @else if $type == 'chevron-down' {
    content: "\f078";
  }
  @else if $type == 'close' {
    content: "\ea0d";
  }
}
