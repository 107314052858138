@charset "utf-8";
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key {
  background: #fff;
  &__inner {
    &.slick-slider {
      margin-bottom: 0;
    }
  }
  &__slide {
    padding: 0 1.5rem;
    a {
      display: block;
      @include mq(sm-) {
        &:hover {
          opacity: 0.5;
        }
      }
    }
    img {
      max-width: 100%;
    }
  }
  .slick-dots {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $margin_sm / 2;
    li {
      margin: 0 0.9rem;
      width: 0.8rem;
      height: 0.8rem;
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        background: #dddddd;
        border-radius: 50%;
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background: $main_color;
        }
      }
    }
  }
  @include mq(sm) {
    &__slide {
      padding: 0 1vw;
    }
    .slick-dots {
      margin-top: $margin_ss/2;
    }
  }
  @include mq(xs) {
    &__slide {
      padding: 0;
    }
  }
  @include mq(ss) {
    .slick-dots {
      li {
        width: 0.45rem;
        height: 0.45rem;
      }
    }
  }
}
/*-------------------------------
  INDEX > SEARCH
-------------------------------*/
.p-idx_search {
  padding: $margin_ss 0 0;
  background: #fff;
  text-align: center;
}
/*-------------------------------
  INDEX > BANNER
-------------------------------*/
.p-idx_banner {
  padding: $margin_ss 0;
  background: #fff;
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    > * {
      width: 50%;
      padding: 0 1rem;
      a {
        display: block;
        @include mq(sm-) {
          &:hover {
            opacity: 0.5;
          }
        }
      }
      img {
        width: 100%;
      }
    }
  }
  @include mq(xs-) {
    &__list {
      > * {
        &:nth-child(n + 3) {
          margin-top: 2rem;
        }
      }
    }
  }
  @include mq(xs) {
    &__list {
      display: block;
      > * {
        width: 100%;
        &:nth-child(n + 2) {
          margin-top: $margin_ss/2;
        }
      }
    }
  }
}
/*-------------------------------
  INDEX > SEARCH
-------------------------------*/
.p-idx_ranking {
  padding: $margin_md 0;
  text-align: center;
  @include mq(sm) {
    padding: $margin_sm 0;
    // @include mq(xs-){
    //   .c-item_list{
    //     li{
    //       width: 48%;
    //       margin-right: 4%;
    //       &:nth-child(even){
    //         margin-right: 0;
    //       }
    //       &:nth-child(n+3){
    //         margin-top: $margin_ss;
    //       }
    //     }
    //   }
    // }
  }
}
/*-------------------------------
  INDEX > NEWS
-------------------------------*/
.p-idx_news {
  padding: $margin_lg 0;
  background: #fff;
  &__head {
    .inner {
      display: inline-block;
    }
  }
  @include mq(sm-) {
    &__head {
      width: 12.5%;
      .c-ttl01 {
        margin-bottom: 2em;
      }
    }
    &__body {
      width: 87.5%;
    }
  }
  @include mq(sm) {
    padding: $margin_md 0;
    .c-inner {
      display: block;
    }
    &__head {
      margin-bottom: $margin_ss/2;
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .c-news_list {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  @include mq(xs) {
    padding: $margin_ss 0;
  }
  @include mq(ss) {
    &__head {
      margin-bottom: 1rem;
    }
  }
}
/*-------------------------------
  INDEX > CATEGORY
-------------------------------*/
.p-idx_category {
  padding: $margin_md 0;
  background: url("../img/index/bg_category.png") no-repeat left bottom, #f4ebdb;
  &__inner {
    position: relative;
    justify-content: space-between;
  }
  &__text {
    position: relative;
    background: #fff;
    padding: $margin_sm/2 $margin_sm;
    z-index: 2;
  }
  @include mq(sm-) {
    &__text {
      margin-top: $margin_md;
      width: 50%;
    }
    &__img {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: calc(50% + #{$margin_sm});
    }
  }
  &__slide {
    width: 100%;
    figure {
      img {
        width: 100%;
      }
    }
  }
  @include mq(sm) {
    background: url("../img/index/bg_category.png") no-repeat left bottom,
      url("../img/index/bg_category.png") no-repeat right top, #f4ebdb;
    background-size: 50% auto;
    padding: $margin_sm 0;
    &__inner {
      display: block;
    }
    &__slide {
      width: 100%;
    }
    &__text {
      padding: $margin_ss/2 $margin_ss $margin_sm/2;
    }
  }
  @include mq(xs) {
    padding: $margin_ss 0;
    &__text {
      padding: $margin_ss/4 4%;
    }
  }
}
/*-------------------------------
  INDEX > INTRO
-------------------------------*/
.p-idx_intro {
  &__img {
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__text {
    padding: $margin_sm 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row-reverse;
    .c-ttl02 {
      margin-left: $margin_md;
    }
    .c-lead_txt {
      padding-top: $margin_sm;
      margin-left: $margin_md;
    }
    p {
      padding-top: $margin_sm;
      line-height: 3.5;
      font-size: 1.125em;
    }
  }
  @include mq(sm) {
    &__text {
      .c-ttl02 {
        margin-left: $margin_ss;
      }
      .c-lead_txt {
        margin-left: $margin_ss;
        line-height: 1.75;
      }
      p {
        line-height: 2.5;
      }
    }
  }
  @include mq(xs) {
    &__img {
      padding-top: 40%;
      background: url("../img/index/img_item.jpg") no-repeat center center / cover;
    }
    &__text {
      padding: $margin_sm 0;
      display: block;
      .c-ttl02 {
        margin-left: 0;
      }
      .c-lead_txt {
        padding-top: 0;
        margin-left: 0;
        line-height: 1.875;
        font-size: 2rem;
      }
      p {
        padding-top: 0;
        font-size: 1em;
        margin-top: 1.5em;
        line-height: 1.6;
      }
    }
  }
  @include mq(ss) {
    &__text {
      .c-lead_txt {
        font-size: 1.875rem;
      }
      p {
        padding-top: 0;
        font-size: 0.95em;
        margin-top: 1.5em;
        line-height: 1.6;
      }
    }
  }
}

/*-------------------------------
  INDEX > SHOP
-------------------------------*/
.p-idx_shop {
  position: relative;
  overflow: hidden;
  &__inner {
    position: relative;
    padding: 10rem 0;
    &:before {
      position: absolute;
      content: "";
      right: 7rem;
      top: 0;
      width: 100vw;
      height: 100%;
      background: #fff;
      z-index: 1;
    }
    &:after {
      position: absolute;
      content: "";
      left: 20rem;
      top: 10rem;
      width: 59%;
      padding-top: 50%;
      background: url("../img/index/bg_shop.png") no-repeat right top / contain;
      z-index: 2;
    }
  }
  &__text {
    position: relative;
    z-index: 3;
    .c-ttl02 {
      margin-bottom: 1em;
    }
    p {
      margin-bottom: 3.125em;
    }
  }
  &__img {
    position: absolute;
    right: 0;
    top: 10rem;
    width: 50vw;
    height: calc(100% - 10rem * 2);
    padding-top: 0;
    z-index: 4;
  }
  @include mq(sm) {
    &__inner {
      background: #fff;
      overflow: hidden;
      padding: $margin_ss;
      &:before {
        position: absolute;
        content: "";
        width: 40%;
        padding-top: 25%;
        height: 0;
        right: 4rem;
        top: 4rem;
        background: url("../img/index/img_shop.jpg") no-repeat center left / cover;
        z-index: 2;
      }
      &:after {
        width: 55%;
        padding-top: 38.5%;
        background: url("../img/index/bg_shop.png") no-repeat left top / 100% auto;
        z-index: 1;
        @include mq(xs-) {
          right: 4rem;
          bottom: 4rem;
          left: initial;
          top: initial;
        }
      }
    }
    &__text {
      width: 55%;
      p {
        margin-bottom: 1.5em;
      }
    }
  }
  @include mq(xs) {
    &__inner {
      background: none;
      padding: 0;
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    &__text {
      width: 100%;
      .info_box {
        background: #fff;
        padding: $margin_ss;
      }
    }
    &__img {
      position: relative;
      width: 94%;
      padding-top: 42.3%;
      height: 0;
      top: 0;
      left: 3%;
      transform: translate(0, 0);
    }
  }
  @include mq(ss) {
    &__text {
      .c-ttl02 {
        &:before {
          width: 125%;
          padding-top: 87.5%;
          transform: translateX(40%);
        }
      }
      .info_box {
        padding: $margin_sm / 2 $margin_ss / 2;
      }
    }
  }
}
/*-------------------------------
  INDEX > CONTACT
-------------------------------*/
.p-idx_contact {
  padding: $margin_lg 0 $margin_md;
  &__head {
    width: 25%;
  }
  &__body {
    width: 75%;
  }
  @include mq(sm) {
    &__inner {
      display: block;
    }
    &__head {
      width: 100%;
    }
    &__body {
      width: 100%;
    }
  }
  @include mq(sm) {
    padding: $margin_md 0 $margin_sm;
  }
  @include mq(xs) {
    padding: $margin_sm 0 0;
  }
}
