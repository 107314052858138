@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer{
  padding-bottom: 5.4em;
  &__cart{
    position: fixed;
    right: 4%;
    bottom: 4%;
    z-index: 50;
    span{
      position: relative;
      display: block;
      font-size: 2em;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      background: $main_color;
      transition: $transition_val;
      border:solid 1px #666;
    }
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      fill:#fff;
      transform: translate(-50%,-50%);
    }
    a{
      display: block;
      @include mq(sm-){
        &:hover{
          opacity: .5;
          span{
            transform:rotateY(360deg);
          }
        }
      }
    }
  }
  &__msg{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $main_color;
    padding: 2em 0;
    line-height: 1.4;
    text-align: center;
    color: #fff;
    font-weight: 600;
    z-index: 30;
  }
  @include mq(sm){
    &__cart{
      bottom: 8%;
    }
  }
  @include mq(xs){
    padding-bottom: 6.8em;
    &__cart{
      bottom: 12%;
    }
  }
  @include mq(ss){
    &__cart{
      span{
        font-size: 1.875em;
      }
    }
  }
}
.l-footer_top{
  padding: $margin_md 0;
  background: $main_color;
  color:#fff;
  .c-inner{
    justify-content: space-between;
  }
  &__info{
    h2{
      font-size: 1.8rem;
      line-height: 1.777;
    }
    .info_box{
      .sub_ttl{
        line-height: 1;
        margin-bottom: 1em;
      }
      .tel{
        line-height: 1;
        >*{
          display: block;
        }
        a{
          color:#fff;
          font-size: 3.6rem;
        }
        span{
          margin-top: .675em;
        }
      }
    }
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      h2{
        border-right: solid 1px #fff;
        padding-right: $margin_md;
      }
      .info_box{
        padding: 1em 0 1.5em $margin_md;
      }
    }
  }
  &__sns{
    .sns_box{
      justify-content: space-between;
      li{
        a{
          display: block;
          img{
            transition: .6s;
          }
          @include mq(sm-){
            &:hover{
              img{
                transform:rotateY(360deg);
              }
            }
          }
        }
      }
    }
    .bnr_blog{
      margin-top: $margin_ss/2;
      background: #fff;
      a{
        @include mq(sm-){
          &:hover{
            opacity: .5;
          }
        }
      }
    }
  }
  @include mq(sm){
    padding: $margin_ss 0;
    .c-inner{
      align-items: center;
      justify-content: center;
    }
    &__info{
      h2{
        font-size: 1em;
        margin-bottom: .25em;
      }
      .info_box{
        .sub_ttl{
          font-size: .875em;
        }
      }
    }
    &__sns{
      margin-left: $margin_md;
    }
  }
  @include mq(ss){
    display: block;
    &__info{
      text-align: center;
      .info_box{
        .tel{
          a{
            font-size: 3rem;
          }
        }
      }
    }
    &__sns{
      text-align: center;
      margin-left: 0;
      margin-top: $margin_ss/2;
    }
  }
}
.l-footer_bottom{
  padding: $margin_md 0;
  &__inner{
    justify-content: space-between;
    align-items: center;
  }
  &__menu{
    line-height: 1;
    font-size: 1.4rem;
    li{
      &:nth-child(n+2){
        margin-left: 2.7em
      }
      a{
        @include mq(sm-){
          &:hover{
            opacity: .5;
          }
        }
      }
    }
  }
  &__sns{
    line-height: 1;
    li{
      &:nth-child(n+2){
        margin-left: 1.5em;
      }
      a{
        svg{
          transition:.6s;
        }
        @include mq(sm-){
          &:hover{
            opacity: .5;
            svg{
              transform:rotateY(360deg);
            }
          }
        }
      }
    }
  }
  &__copy{
    width: 100%;
    line-height: 1;
    font-size: 1.4rem;
    margin-top: 2em;
  }
  @include mq(sm){
    padding: $margin_ss 0;
  }
  @include mq(ss){
    padding: $margin_ss /2 0;
    &__inner{
      display: block;
    }
    &__menu{
      padding: 0;
      font-size: 1.2rem;
      ul{
        display: block;
      }
      li{
        &:nth-child(n+2){
          margin-left: 0;
          margin-top: 2em;
        }
      }
    }
    &__copy{
      font-size: 1.2rem;
    }
  }
}
