@charset "utf-8";
/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: $base_width;
  max-width: $base_width_max;
  margin-left: auto;
  margin-right: auto;
  &.-full {
    max-width: $base_width;
  }
}
.c-key_under{
  padding-top: $margin_lg;
  background: url('../img/common/img_under_key.jpg') no-repeat center center / cover;
  margin-bottom: $margin_lg/2;
  &__inner{
    border-left: solid 1px $main_color;
    padding-left: $margin_ss;
    padding-bottom: $margin_lg / 2;
  }
  &__ttl{
    padding: .5em 0;
    font-size: 4rem;
    font-weight: 400;
    line-height: 1;
  }
  &__text{
    font-size: 1.8rem;
  }
  @include mq(sm){
    padding-top: $margin_sm;
    margin-bottom: 0;
    &__inner{
      padding-bottom: $margin_sm;
      padding-left: 0;
      border-left: none;
    }
    &__ttl{
      font-size: 3rem;
    }
  }
  @include mq(xs){
    padding-top: $margin_ss;
    &__inner{
      padding-bottom: $margin_ss;
    }
  }
  @include mq(ss){
  }
}
.c-text_v{
  @include mq(xs-){
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}
.c-lead_txt{
  font-size: 3.6rem;
  line-height: 1;
  font-weight: 400;
  @include mq(xs-){
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    span{
      display: inline-block;
      &:nth-child(n+2){
        margin-right: 1rem;
      }
    }
  }
  &.-sm{
    font-size: 3rem;
    line-height: 2;
    span{
      &:nth-child(n+2){
        margin-right: 0;
      }
    }
  }
  &.-wh{
    span{
      background: #fff;
    }
  }
  @include mq(sm){
    font-size: 3rem;
    &.-sm{
      font-size: 2.6rem;
      line-height: 1.875;
    }
  }
  @include mq(xs){
    &.-sm{
      line-height: 1.458;
    }
  }
  @include mq(ss){
    &.-sm{
      font-size: 2rem;
    }
  }
}
.c-common_sec{
  margin-top: $margin_md;
  p{
    margin-top: 2em;
    line-height: 2;
  }
  .c-btnwrap{
    margin-top: 2em;
  }
}
/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-ttl01{
  line-height: 1;
  font-size: 2.6rem;
  font-weight: 400;
  @include mq(sm-){
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span{
      display: inline-block;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      &:nth-child(n+2){
        margin-right: 1rem;
      }
    }
  }
  @include mq(ss){
    font-size: 1.8rem;
  }
}

.c-ttl02{
  font-size: 4.8rem;
  line-height: 1;
  font-weight: 400;
  span{
    position: relative;
    display: inline-block;
    padding-right: .75em;
    &:after{
      position: absolute;
      content:"";
      width: 1px;
      height: 1.1em;
      background: #000;
      top: 0;
      right: 0;
    }
  }
  @include mq(xs-){
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
  @include mq(sm){
    font-size: 3.6rem;
  }
  @include mq(xs){
    font-size: 3rem;
    margin-bottom: 1em;
    span{
      padding-bottom: .35em;
      &:after{
        width: 4em;
        height: 1px;
        top: initial;
        right: initial;
        left: 0;
        bottom: 0;
      }
    }
  }
  @include mq(ss){
    font-size: 2.4rem;
  }
}

.c-ttl03{
  font-size:  1.8rem;
  font-weight:  600;
  margin-bottom: 1em;
  line-height: 1;
}

.c-ttl04{
  font-size: 3rem;
  font-weight: 400;
  line-height: 1;
  @include mq(ss){
    font-size: 2.4rem;
  }
}


/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  display: flex;
  justify-content: center;
  align-items:center;
  text-align: center;
  &.-fx{
    .link_btn{
      width: calc((100% - 4rem) / 2 );
      a{
        display: block;
      }
      @include mq(xs-){
        &:nth-child(n+2){
          margin-left:4rem;
        }
      }
      @include mq(xs){
        &:nth-child(n+2){
          margin-top:2rem;
        }
      }
    }
  }
  @include mq(xs){
    display: block;
    &.-fx{
      .link_btn{
        width:100%;
      }
    }
  }
}

.c-btn01{
  line-height: 1;
  text-align: center;
  .link_btn{
    position: relative;
    display: block;
    min-width: 260px;
    background: $main_color;
    color:#fff;
    outline:none;
    border-radius: 0;
    border:solid 1px $main_color;
    padding:1.5em;
    transition: .6s;
    cursor: pointer;
    @include mq(sm-){
      &:hover{
        background: #fff;
        color: $main_color;
      }
    }
  }
  @include mq(xs){
    .link_btn{
      width: 100%;
      min-width: 100%
    }
  }
}

.c-link01{
  line-height: 1;
  a{
    position: relative;
    padding-left: 1em;
    &:before{
      position: absolute;
      @include icon(chevron-right);
      font-size: .5em;
      left: 0;
      top: 50%;
      transform:translateY(-50%);
      transition:.6s;
    }
    @include mq(sm-){
      &:hover{
        &:before{
          opacity: .5;
          left: .5em;
        }
      }
    }
  }
  @include mq(ss){
    a{
      font-size: .85em;
    }
  }
}

.c-link02{
  line-height: 1;
  a{
    position: relative;
    display: inline-block;
    padding: 1em 1em 1em;
    border-bottom:solid 2px $main_color;
    transition:.6s;
    &:after{
      position: absolute;
      right: 1em;
      top: 2.5em;
      @include icon(chevron-right);
      font-size: .5em;
      z-index: 2;
    }
    span{
      position: relative;
      display: block;
      padding-right: 2em;
      z-index: 2;
    }
    &:before{
      position: absolute;
      content:"";
      width: 100%;
      height: 0;
      background: $main_color;
      bottom:0;
      left: 0;
      z-index: 1;
      transition:.6s;
    }
    @include mq(sm-){
      &:hover{
        color:#fff;
        &:before{
          height: 100%;
        }
      }
    }
  }
  @include mq(ss){
    a{
      span{
        padding-right: 5em;
      }
    }
  }
}

/*--------------------------------------------
  TABLE
--------------------------------------------*/
.c-tblbox{
  .sheet01{
    tr{
      border-bottom:solid 1px $font_color;
    }
    th,
    td{
      vertical-align: top;
      padding: 1.5em 0;
      line-height: 1.6;
      font-size: 1.8rem;
    }
    th{
      width: 30%;
      text-align: left;
      padding-right: 1em;
    }
    td{
      width: 70%;
      padding-left: 1em;
    }
  }
  @include mq(sm-){
    &.-fx{
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      table{
        width: 47%;
      }
    }
  }
  @include mq(ss){
    .sheet01{
      tr{
        display: block;
        width: 100%;
        padding: 1.5em 0;
      }
      th,
      td{
        display: block;
        width: 100%;
        padding: 0;
        font-size: 1.6rem;
      }
      th{
        margin-bottom: .75em;
      }
    }
  }
}
.c-tbl_responsive{
  @include mq(sm){
    .rwd_sheet{
      overflow-x:auto;
      overflow-y: visible;
      table{
        min-width: 100%;
        width:auto;
        th,
        td{
          white-space: nowrap;
        }
      }
    }
    &:after{
      display: block;
      content:"横にスクロールできます";
      text-align: center;
      margin-top: .5em;
    }
  }
}


/*--------------------------------------------
  PARTS - NEWS
--------------------------------------------*/
.c-news_list{
  li{
    a{
      position: relative;
      padding: 1.25em  0;
      border-bottom:solid 1px #cccccc;
      >*{
        line-height: 1.4;
      }
      time{
        font-size: .875em;
        width: 7em;
      }
      h3{
        width: calc(100% - 7em);
        font-weight: 400;
      }
      @include mq(sm-){
        &:hover{
          border-color:$font_color;
        }
      }
    }
  }
  @include mq(xs){
    li{
      a{
        display: block;
        font-size: 1.35rem;
        time{
          font-size: .8em;
          width: 100%;
          line-height: 1;
        }
        h3{
          width: 100%;
          margin-top: .7em;
          line-height: 1.6;
        }
      }
    }
  }
}
/*--------------------------------------------
  PARTS - MODAL
--------------------------------------------*/
.c-modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  &__cont{
    position: absolute;
    left: 50%;
    top: 50%;
    max-height: 90%;
    transform:translate(-50%,-50%);
    z-index: 2;
  }
  &__head{
    background: $main_color;
    color: #fff;
    font-size: 1.5em;
    padding: 1em $margin_sm;
    line-height: 1;
    text-align: center;
  }
  &__body{
    padding: $margin_sm;
    background: url('../img/common/bg_common.jpg') repeat center center;
  }
  &__close{
    margin-top: 1.5em;
    .link_btn{
      min-width: 1px;
      padding: .875em 2em;
      width: auto;
      margin:0 auto;
    }
  }
  &__bg{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: 1;
  }
  @include mq(sm){
    &__head{
      padding: 1em $margin_ss;
      font-size: 1.6rem;
    }
    &__body{
      padding: 1.5em 4%;
    }
  }
}
/*--------------------------------------------
  PARTS - SEARCH
--------------------------------------------*/
.c-search{
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-width: 800px;
  width: 100%;
  font-size: 1.6rem;
  margin:0 auto;
  label{
    display: block;
    font-size: 1em;
  }
  &__input{
    width: calc(100% - 6em);
    input{
      display: block;
      width: 100%;
      line-height: 1;
      padding: .75em;
      font-size: 1em;
      height: 100%;
      border: solid 1px #ddd;
      &:focus{
        outline: none;
        background: #f5fbff;
      }
    }
  }
  &__submit{
    width: 6em;
    .link_btn{
      display: block;
      padding: .75em;
      width: 100%;
      line-height: 1;
      min-width:100%;
      height: 100%;
    }
  }
  @include mq(ss){
    &__input{
      width: calc(100% - 4em);
      input{
        padding: .5em;
      }
    }
    &__submit{
      width: 4em;
      .link_btn{
        font-size: .875em;
      }
    }
  }
}
/*--------------------------------------------
  PARTS - ITEM
--------------------------------------------*/
.c-item_list{
  $amp: &;
  justify-content: flex-start;
  @include mq(xs-){
    li{
      width:23.5%;
      margin-right: 2%;
      &:nth-child(4n){
        margin-right: 0;
      }
      &:nth-child(n+5){
        margin-top: $margin_md;
      }
      a{
        display: block;
        @include mq(sm-){
          &:hover{
            opacity: .5;
          }
        }
      }
    }
  }
  &__thumb{
    position: relative;
    padding-top: 1rem;
    img{
      position: relative;
      z-index: 1;
      max-width: 100%;
      height: auto;
    }
    span{
      position: absolute;
      left: 2rem;
      top: 0;
      line-height: 1;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      color: #fff;
      background: $main_color;
      box-shadow: 0 0 2px rgba(0,0,0,.1);
      padding: 1.25em .5em;
      z-index: 2;
      &.no1{
        background: #DBB400;
      }
      &.no2{
        background: #9EACB4;
      }
      &.no3{
        background: #C47022;
      }
      &.no4{
        background: #B1262B;
      }
    }
  }
  &__title{
    font-size: 1.125em;
    line-height: 1.6;
    font-weight: 400;
  }
  &__text{
    padding-top: $margin_ss/2;
    p{
      margin-top: .5rem;
      line-height: 1.4;
      span{
        color: #c11;
      }
    }
  }
  @include mq(sm){
    &__thumb{
      span{
        font-size: 1.2rem;
        padding: 1em .5em;
      }
    }
  }
  @include mq(xs){
    li{
      width: 48%;
      margin-right: 4%;
      &:nth-child(even){
        margin-right: 0;
      }
      &:nth-child(n+3){
        margin-top: $margin_ss;
      }
    }
    &.-single{
      li{
        a{
          display: flex;
          justify-content: space-between;
          align-items: center;
          #{$amp}__thumb{
            width: 40%;
            padding-top: 0;
            padding-left: 1rem;
            span{
              padding: .5em .75em;
              font-size: 1rem;
              top: .5rem;
              left: 0;
              -ms-writing-mode: lr-tb;
              writing-mode: horizontal-tb;
            }
          }
          #{$amp}__text{
            width: 55%;
            padding-top: 0;
          }
        }
        #{$amp}__title{
          font-size: 1.8rem;
        }
      }
    }
  }
  @include mq(ss){
    &__title{
      font-size: 1.25em;
    }
    &__thumb{
      span{
        font-size: 1rem;
        left: 1rem;
      }
    }
  }
}

.c-item_cat_list{
  li{
    a{
      position: relative;
      border-bottom:solid 1px #ccc;
      display: block;
      font-size: 1.8rem;
      padding: 1.6666em calc(1.6666em + 2rem) 1.6666em 0;
      line-height: 1;
      .number{
        margin-right:1em;
      }
      span{
        padding-right: 2em;
      }
      .arrow{
        position: absolute;
        right: 1.6666em;
        top: calc(50% - 2px);
        height: 4px;
        width: 2rem;
        border-bottom:solid 1px $font_color;
        transition:.6s;
        &:after{
          position: absolute;
          content:"";
          right:0;
          bottom:0;
          border-style:solid;
          border-width: 0 .6rem 4px 0;
          border-color:$font_color transparent;
        }
      }
      @include mq(sm-){
        &:hover{
          border-bottom-color:$font_color;
          .arrow{
            right:1em;
          }
        }
      }
    }
  }
  @include mq(sm){
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      li{
        width: 48%;
        a{
          font-size: 1.6rem;
          padding: 1.5em 2rem 1.5em 0;
          .number{
            margin-right:.5em;
          }
          .arrow{
            right: 0;
          }
          span{
            padding-right: 0;
          }
        }
      }
    }
  }
  @include mq(xs){
    li{
      a{
        font-size: 1.5rem;
        padding: 1.25em 0;
        .number{
          font-size: .75em;
        }
        .arrow{
          display: none;
        }
        span{
          padding-right: 0;
        }
      }
    }
  }
}

.c-item_info_sheet{
  tr{
    border-top:solid 1px $font_color;
    &:last-child{
      border-bottom:solid 1px $font_color;
    }
  }
  th,
  td{
    text-align: left;
    padding: 1em 0;
    vertical-align: top;
    font-weight: 400;
    line-height: 1.6;
  }
  th{
    width: 25%;
    padding-right: 1em;
  }
  td{
    width: 75%;
    padding-left: 1em;
  }
  @include mq(sm){
    th{
      width: 35%;
    }
    td{
      width: 65%;
    }
  }
}
/*--------------------------------------------
  PARTS - FORM
--------------------------------------------*/
.c-form_box{
  $amp :&;

  input:not([type='radio']):not([type='checkbox']),
  select,
  textarea {
    width: 100%;
    font-size:1.6rem;
    border:none;
    line-height: 1.3;
    display: inline-block;
    padding:.85em .75em .8em;
    &:focus{
      outline:none;
    }
  }
  select {
    padding:.75em 1.5em .75em .75em;

    .ios & {
      -webkit-appearance: button;
      appearance: button;

      @include mq(sm) {
      }
    }
  }


  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="url"],
  input[type="search"],
  textarea{
    vertical-align: middle;
    &:fucus{
      outline:none;
      background:#ccc;
    }
  }
  textarea {
    min-height: 12.5em;
    line-height: 1.5;
    @include mq(xs) {
      min-height: 10em;
    }
  }
  table{
    tr{
      border-bottom:solid 1px #ccc;
      &[data-row="textarea"]{
        th,
        td{
          vertical-align: top;
        }
        @include mq(xs-){
          .form_label{
            padding-top: 1em;
          }
        }
      }
    }
    th,
    td{
      text-align: left;
      vertical-align: middle;
      padding: 1.875em 0;
    }
    th{
      width: 22%;
    }
    td{
      width: 78%;
    }
  }
  .form_label{
    &.-required{
      &:after{
        content:"※";
        margin-left: .5em;
      }
    }
  }
  .form_input{
    padding-right: $margin_lg;
    &.-short{
      >.input_tag{
        width: 30%;
      }
    }
    &.-half{
      >.input_tag{
        width: 50%;
      }
    }
    &.-middle{
      >.input_tag{
        width: 60%;
      }
    }
    &.-long{
      >.input_tag{
        width: 80%;
      }
    }
    &.-full{
      >.input_tag{
        width: 100%;
      }
    }
  }
  .annotation{
    position: relative;
    margin-top: 1em;
    padding-left: 1.5em;
    &:before{
      position: absolute;
      content:"※";
      left: 0;
      top: 0;
    }
  }
  .form_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.125em;
  }
  @include mq(sm){
    table{
      th{
        width: 30%;
      }
      td{
        width: 70%;
      }
    }
  }
  @include mq(sm){
    table{
      th,
      td{
        display: block;
        width: 100%;
      }
      th{
        padding-bottom: 1.25em;
      }
      td{
        padding-top: 0;
      }
    }
    .form_input{
      padding-right: 0;
      &.-short,
      &.-half,
      &.-middle,
      &.-long,
      &.-full{
        >.input_tag{
          width: 100%;
        }
      }
    }
    .annotation{
      margin-top: 2em;
    }
    .form_btn{
      display: block;
    }
  }
}
/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    &.cover{
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;';
    }

    &.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}


/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-ta_left {
  text-align: left;
}

.c-ta_center {
  text-align: center;
}

.c-ta_right {
  text-align: right;
}

.c-ta_justify{
  @include mq(ss-){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-pe_none {
  pointer-events: none;
}


.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items:stretch;
  flex-wrap: wrap;
}

@include mq(sm-) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default;
  }
}
