@charset "utf-8";
/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header{
  position: relative;
  padding: $margin_lg/2 $margin_md/2;
  @include mq(sm){
    padding: $margin_sm/2 $margin_md/2;
  }
  @include mq(ss){
    padding: 1.5rem;
  }
}

.l-header_top{
  justify-content: space-between;
  align-items: center;
  margin-bottom:$margin_lg/2;
  &__catch{
    font-weight: 600;
    width: calc((100% - 15.5rem) / 2);
  }
  &__logo{
    width: 15.5rem;
    a{
      display: block;
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  &__toggle{
    position: relative;
    z-index: 102;
    width: 3.7rem;
    height: 2.6rem;
    .so_toggle{
      position: absolute;
      background: transparent;
      padding:0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      .hamburger-icon{
        height: .2rem;
        background: $main_color;
        &:before,&:after{
          top: 0;
          height: 100%;
          background: $main_color;
          transform-origin:50% 50%;
        }
        &:before{
          transform:translateY(-1.2rem);
        }
        &:after{
          transform:translateY(1.2rem);
        }
      }
    }
  }
  &__menu{
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 600;
    width: calc((100% - 15.5rem) / 2);
    ul{
      justify-content: flex-end;
      align-items: center;
      @include mq(sm-){
        &:nth-child(n+2){
          margin-top:2em;
        }
      }
      li{
        @include mq(sm-){
          &:nth-child(n+2){
            margin-left: 1.666em;
          }

        }
        a{
          &.i_text{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $font_color;
            svg{
              font-size: 1.125em;
            }
            span{
              margin-left: .5em;
            }
            &.-favorite{
              align-items: flex-end;
            }
            &.-shipping{
              svg{
                font-size: 1.5em;
              }
            }
            &.-search{
              .on{
                display: none;
              }
              .off{
                display: block;
              }
              &.is-act{
                position: relative;
                z-index: 11;
                .on{
                  display: block;
                }
                .off{
                  display: none;
                }
              }
            }
            @include mq(sm-){
              &:hover{
                opacity: .5;
              }
            }
          }
        }
      }
    }
  }
  .slideout-open & {
    &__toggle{
      .so_toggle{
        .hamburger-icon{
          background: rgba(255,255,255,0);
          &:before,&:after{
            background: #fff;
          }
          &:before{
            transform:rotate(45deg);
          }
          &:after{
            transform:rotate(-45deg);
          }
        }
      }
      .so_text{
        color: #fff;
      }
    }
  }
  @include mq(md){
    &__catch{
      width: calc((100% - 12.5rem) / 2);
    }
    &__logo{
      width: 12.5rem;
    }
    &__menu{
      width: calc((100% - 12.5rem) / 2);
    }
  }
  @include mq(sm){
    margin-bottom: 0;
    &__logo{
      order: 2;
      width: 11rem;
    }
    &__menu{
      order: 1;
      width: auto;
      ul{
        display: block;
        li{
          a{
            &.i_text{
              svg{
                fill: $main_color;
                font-size: 3rem;
              }
              span{
                display: none;
              }
            }
          }
        }
      }
    }
    &__toggle{
      order: 3;
      width: 3.7rem;
      height: 4.7rem;
      .so_toggle{
        position: absolute;
        width: 100%;
        height: 3rem;
      }
      .so_text{
        position: absolute;
        width: 100%;
        font-size: 1.2rem;
        line-height: 1;
        text-align: center;
        left: 0;
        bottom: 0;
        font-weight: 600;
      }
    }
  }
  @include mq(ss){
    &__logo{
      width: 8rem;
    }
  }
}