@charset "utf-8";
/*============================================

  LAYOUT

============================================*/
/*-------------------------------
  FRAMEWORK
-------------------------------*/
.l-page{
}
/*-------------------------------
  BODY
-------------------------------*/
.l-main{
  padding-bottom: $margin_md;
  background: url('../img/common/bg_common.jpg') repeat-y center center / 100% auto;
  @include mq(sm){
    padding-bottom: $margin_sm;
  }
}
/*-------------------------------
  MEGAMENU
-------------------------------*/
.l-megamenu{
  position: relative;
  &__list{
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
    background: url('../img/common/bg_common.jpg') repeat center center;
    padding: $margin_ss 0;
    display: none;
    ul{
      justify-content: center;
      align-items: stretch;
      li{
        width: 32%;
        padding: 0;
        &:nth-child(n+2){
          margin-left: 2%;
        }
        a{
          display: block;
          figure{
            width: 100%;
            background: $main_color;
            img{
              transition:$transition_val;
              max-width: 100%;
              height: auto;
            }
          }
          p{
            text-align: center;
            font-weight: 600;
            margin-top: 1em;
          }
          @include mq(sm-){
            &:hover{
              opacity: .5;
            }
          }
        }
      }
    }
  }
}
/*-------------------------------
  BREADCRUMB
-------------------------------*/
.l-breadcrumb{
  line-height: 1;
  font-size: 1.4rem;
  &__list{
    justify-content: flex-start;
    align-items: center;
    li{
      position: relative;
      padding-right:1.25em;
      &:after{
        display: inline-block;
        transform:scale(.65);
        @include icon(chevron-right);
        margin-left: 1.25em;
      }
      a{
        position: relative;
        display: inline-block;
        &:hover{
          opacity: .5
        }
      }
      &:last-child{
        padding-right:0;
        &:after{
          display: none;
        }
        a{
          pointer-events: none;
        }
      }
    }
  }
}