@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi{
  @include mq(sm-){
    // slideout 打ち消し
    position: static;
    background: transparent;
    width: auto;
    overflow: hidden;
    &__page{
      justify-content: center;
      align-items: center;
      li{
        padding: 0 2.5rem;
        .nav_item{
          display: block;
          span{
            color: $font_color;
            font-weight: 600;
            transition: $transition_val;
            cursor: pointer;
          }
          @include mq(sm-){
            &:hover{
              span{
                opacity: .5;
              }
            }
          }
        }
      }
    }
  }
  @include mq(sm){
    background: rgba(0,0,0,.75);
    padding: $margin_lg 4%;
    &__inner{
      padding-top: $margin_sm;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }
    font-size: 2.5rem;
    right: 0;
    transform:translateX(100%);
    &__items{
      width: 100%;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 4%;
      padding-bottom: 6%;
      font-size: 1.8rem;
      border-bottom:solid 1px rgba(255,255,255,.25);
      order: 1;
      li{
        width: 31%;
        &:nth-child(n+4){
          margin-top: 4%;
        }
        a{
          color: #fff;
          text-align: center;
          line-height: 1.5;
        }
        figure{
          img{
            max-width: 100%;
            height: auto;
          }
        }
        p{
          margin-top: .5em;
        }
      }
    }
    &__page,
    &__shop{
      display: block;
      width: 48%;
      li{
        padding: 0;
        font-size: 1.8rem;
        &:nth-child(n+2){
          margin-top: 1em;
        }
        a{
          color:#fff;
        }
      }
    }
    &__page{
      order: 2;
    }
    &__shop{
      order: 3;
    }
    .slideout-open &{
      transform:translateX(0);
    }
  }
  @include mq(ss){
    &__inner{
      padding-top: 0;
      flex-direction:column;
    }
    &__items{
      padding-bottom: $margin_ss;
      margin-bottom: $margin_ss;
      font-size: 1.4rem;
      li{
        width: 47%;
        &:nth-child(n+3){
          margin-top: 6%;
        }
      }
    }
    &__page,
    &__shop{
      width: 100%;
    }
    &__shop{
      margin-top: 6%;
    }
  }
}