@charset "utf-8";
/*============================================

  ITEM

============================================*/
/*-------------------------------
  ITEM > ARCHIVE
-------------------------------*/
.p-item_archive{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_sm 0 0;
  }
}
.p-item_list{
  .c-ttl04{
    margin-bottom: .75em;
  }
  &:nth-child(n+2){
    padding-top: $margin_sm;
    margin-top: $margin_sm;
    border-top:solid 1px $font_color;
  }
}
/*-------------------------------
  ITEM > SINGLE
-------------------------------*/
.p-item_single{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_sm 0 0;
  }
}

.p-item_single_top{
  margin-bottom: $margin_lg;
  justify-content: space-between;
  align-items: flex-start;
  &__img{
    width: 41.6%;
  }
  &__text{
    width: 50%;
  }
  @include mq(sm){
    margin-bottom: $margin_ss;
    &__img{
      width: 40%;
    }
    &__text{
      width: 55%;
    }
  }
  @include mq(xs){
    &__img{
      width: 100%;
      margin-bottom: $margin_ss;
    }
    &__text{
      width: 100%;
    }
    @include mq(ss-){
      &__img{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
      }
    }
  }
}
.p-item_slide{
  img{
    max-width: 100%;
    height: auto;
  }
  &.-main{
    margin-bottom: 0;
  }
  &.-thumb{
    margin-top: 4%;
    .slick-dots{
      position: static;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      li{
        margin:0;
        width: 32%;
        height: auto;
        margin-right: 2%;
        img{
          max-width: 100%;
        }
        @include mq(xs-){
          &:nth-child(3n){
            margin-right: 0;
          }
          &:nth-child(n+4){
            margin-top: 2%;
          }
        }
      }
    }
  }
  @include mq(xs){
    @include mq(ss-){
      &.-main{
        width: 60%;
      }
      &.-thumb{
        width: 35%;
        margin-top: 0;
        .slick-dots{
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          li{
            width: 48%;
            margin-right: 0;
            &:nth-child(n+3){
              margin-top: 4%;
            }
          }
        }
      }
    }
  }
}

.p-item_detail{
  &__badge{
    line-height: 1;
    margin-bottom: .75em;
    span{
      display: inline-block;
      background: $main_color;
      color: #fff;
      padding: .5em 1em .4em;
      font-weight: 600;
    }
  }
  &__title{
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: .5em;
  }
  &__price{
    line-height: 1;
    margin-bottom: 1.25em;
    span{
      font-size: 1.5em;
    }
  }
  &__soldout{
    color: #c11;
    font-size: 1.25em;
    margin-bottom: 1em;
  }
  &__cart{
    margin-bottom: $margin_lg/2;
    .cartjs_box{
      padding: 0!important;
      border:none !important;
    }
    .cartjs_block{
      padding: 0 !important;
    }
    .cartjs_buy,
    .cartjs_cart_in{
      margin-top: $margin_ss/2 !important;
      text-align: left !important;
      input[type="submit"]{
        display: inline-block !important;
        margin-bottom: 0 !important;
        background: $main_color !important;
        color:#fff !important;
        border-radius:0 !important;
        width: auto !important;
        height:auto !important;
        font-size: 1.6rem !important;
        border:solid 1px $main_color !important;
        text-shadow: none !important;
        padding:1.375em 1em !important;
        margin:0 auto !important;
        font-weight: 400 !important;
        box-shadow: none !important;
        line-height: 1;
        min-width: 260px;
        outline:none;
        transition:$transition_val;
        @include mq(sm-){
          &:hover{
            background: #fff !important;
            color: $font_color !important;
          }
        }
      }
    }
    table{
      width: 100% !important;
      margin:0 !important;
    }
    th,td{
      padding:.5rem 0 !important;
      vertical-align: middle !important;
      background: none !important;
    }
    th{
      width: 25% !important;
    }
    td{
      width: 75% !important;
    }
    input[name="product_num"]{
      width: 8rem !important;
      padding: .5em 1em .4em !important;
      display: inline-block;
      line-height: 1;
      outline:none;
      border:solid 1px $font_color;
      text-align: left;
      background: transparent;
      &:focus{
        background: #fff;
      }
    }
    select{
      width: 50% !important;
      border-radius:0 !important;
      border:solid 1px $font_color !important;
      background: transparent !important;
      line-height: 1 !important;
      height:auto !important;
      padding: .5em 1em .4em !important;
      color:$font_color;
      outline:none;
      &:focus{
        background: #fff !important;
      }
    }
  }
  &__favorite{
    margin-top: 1em;
    text-align: right;
    a{
      &:before{
        display: inline-block;
        @include icon(favorite);
        font-size: 1.4rem;
        padding-left: .1rem;
        width: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        color: #fff;
        background: $main_color;
        border-radius: 50%;
        margin-right: 1em;
        transition:.6s;
      }
      &:not(.is-act){
        span{
          &.off{
            display: none;
          }
          &.on{
            display: inline-block;
          }
        }
      }
      &.is-act{
        span{
          &.off{
            display: inline-block;
          }
          &.on{
            display: none;
          }
        }
      }
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
  }
  @include mq(sm){
    &__badge{
      font-size: 1.4rem;
      margin-bottom: .5em;
    }
    &__title{
      font-size: 3rem;
    }
    &__cart{
      margin-bottom: $margin_sm/2;
      th{
        width: 40% !important;
      }
      td{
        width: 60% !important;
      }
      select{
        width: 100% !important;
      }
      .cartjs_buy,
      .cartjs_cart_in{
        margin-top: $margin_ss/2 !important;
        text-align: left !important;
        input[type="submit"]{
          padding:1.2em .75em 1.1em!important;
        }
      }
    }
  }
  @include mq(sm){
    &__cart{
      .cartjs_buy,
      .cartjs_cart_in{
        text-align: center !important;
      }
    }
    &__favorite{
      text-align: center;
    }
  }
}

.p-item_single_description{
  margin-bottom: $margin_sm;
}

.p-item_single_middle{
  margin-bottom: $margin_lg;
  justify-content: space-between;
  align-items: stretch;
  .c-ttl04{
    margin-bottom: 1em;
  }
  &__sheet{
    width: 48%;
  }
  @include mq(sm){
    margin-bottom: $margin_sm;
    .c-ttl04{
      font-size: 2.4rem;
    }
  }
  @include mq(xs){
    &__sheet{
      width: 100%;
      &.-info{
        margin-bottom: $margin_sm;
      }
    }
  }
}

.p-item_single_bottom{
  .c-ttl04{
    margin-bottom: 1em;
  }
  &__section{
    &.-history{
      margin-top: $margin_lg;
    }
  }
  @include mq(sm){
    .c-ttl04{
      margin-bottom: .75em;
      font-size: 2.4rem;
    }
    &__section{
      &.-history{
        margin-top: $margin_sm;
      }
    }
  }
}
/*============================================

  LAW

============================================*/
/*-------------------------------
  LAW > MAIN
-------------------------------*/
.p-law_main{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_sm 0 0;
  }
}
/*============================================

  GUIDE

============================================*/
/*-------------------------------
  GUIDE > SHIPPING
-------------------------------*/
.p-guide_mail {
  padding-top: $margin_lg;
  p{
    margin-top: 1.5em;
    margin-bottom: 2em;
  }
  &__list{
    dt{
      position: relative;
      font-size: 1.125em;
      padding-left: 1.25em;
      line-height: 1.4;
      margin-bottom: .5em;
      &:before{
        position: absolute;
        left: 0;
        top: 0;
        content: "■";
      }
    }
    dd{
      &:nth-last-child(n+2){
        margin-bottom: 2em;
      }
    }
  }
  @include mq(sm){
    padding-top: $margin_sm;
  }
}
.p-guide_shipping {
  padding: $margin_lg 0;
  &__sheet{
    margin-top: $margin_ss;
    dl{
      width: 30%;
      @include mq(sm-){
        &:nth-child(n+2){
          margin-left: 5%;
        }
      }
      dt{
        font-size: 2rem;
        margin-bottom: .5em;
      }
      dd{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 1.6;
        .pref{
          width: calc(100% - 4em);
        }
        .price{
          width: 4em;
          text-align: right;
        }
        padding-bottom: $margin_ss/2;
        border-bottom:solid 1px $font_color;
        &:not(:last-child){
          margin-bottom: $margin_ss/2;
        }
      }
    }
  }
  @include mq(sm){
    padding: $margin_sm 0;
    &__sheet{
      display: block;
      dl{
        width: 100%;
        font-size: 1.25em;
        dt,
        dd{
          font-size: 1em;
        }
        dt{
          width: 5em;
          margin-bottom: 0;
          line-height: 1.4;
          color: $font_color;
        }
        dd{
          > * {
            font-size: 80%;
          }
        }
        @include mq(xs-){
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          flex-wrap: wrap;
          dt,
          dd{
            padding: 1em 0;
            margin-top: 0;
            border-bottom:solid 1px $font_color;
          }
          dt{
            width: 5em;
            line-height: 1.4;
          }
          dd{
            width: calc(100% - 5em);
            width: calc(100% - 5em);
            line-height: 1.75;
            &:not(:last-child){
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  @include mq(xs){
    dl{
      margin-top: 1em;
      dt{
        margin-bottom: .5em;
      }
    }
  }
}

/*-------------------------------
  GUIDE > PAYMENT
-------------------------------*/
.p-guide_payment{
  padding-bottom: $margin_lg;
  figure{
    margin-top: 1.25em;
    width: 100%;
    max-width: 400px;
    background: #fff;
    padding: 1rem;
    img{
      max-width: 100%;
    }
  }
  @include mq(sm){
    padding-bottom: 0;
  }
  @include mq(xs){
    .c-tblbox{
      .sheet01{
        font-size: 1.25em;
        tr{
          display: block;
          width: 100%;
          padding: 1.5em 0;
        }
        th,
        td{
          display: block;
          width: 100%;
          padding: 0;
          font-size: 1em;
        }
        th{
          margin-bottom: .5em;
          color: $font_color;
        }
        td{
          font-size: .8em;
        }
      }
    }
  }
}
/*============================================

  NEWS

============================================*/
/*-------------------------------
  NEWS > ARCHIVE
-------------------------------*/
.p-news_archive{
  padding: $margin_sm  0 $margin_lg;
  @include mq(sm){
    padding: $margin_sm 0 0;
  }
}

/*-------------------------------
  NEWS > SINGLE
-------------------------------*/
.p-news_single{
  padding: $margin_sm  0 $margin_lg;
  &__head{
    time{
      display: block;
      line-height: 1;
      margin-bottom: 1em
    }
    h1{
      font-size: 3rem;
      line-height: 1.6;
      font-weight: 400;
      padding-bottom: .5em;
      margin-bottom: 1.5em;
      border-bottom: solid 1px $main_color;
    }
  }
  &__btn{
    margin-top: $margin_sm;
    .link_btn{
      max-width: 300px;
      margin: 0 auto;
    }
  }
  @include mq(sm){
    padding: $margin_sm 0 0;
  }
}

/*============================================

  CONTACT

============================================*/
/*-------------------------------
  CONTACT > INPUT
-------------------------------*/
.p-inq_form{
}
/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound_main{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_md 0;
  }
}
